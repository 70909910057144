<template>
  <div
    id="ignoreFontUpscale"
    class="draftInfluencersPageWrapper mainParentContainer ignoreFontUpscale"
  >
    <NavBarrenPrevious class="navBarrenWrap">
      <span class="tw-mr-1">{{ DRAFT_INFLUENCERS_COUNT }}</span>
      <span v-if="draftId">{{
        $t('hub.footer11', DRAFT_INFLUENCERS_COUNT)
      }}</span>
      <span v-else>{{
        $t('draft.influencers00', DRAFT_INFLUENCERS_COUNT)
      }}</span>
    </NavBarrenPrevious>
    <DraftInfluencers class="content" />
    <CampaignFooter page="cart" :draft-id="draftId" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'

import DraftInfluencers from '~/components/draft/influencers.vue'
import NavBarrenPrevious from '~/components/nav/barrenPrevious.vue'
import CampaignFooter from '~/components/ui/CampaignFooter/index.vue'

import intercomMixin from '~/mixins/intercom'
import snackbarCartMixin from '~/mixins/shared/snackbarCart'

import { useCartStore } from '~/stores/cart'
import { useDraftStore } from '~/stores/draft'
import { useDraftPromoStore } from '~/stores/draftPromo'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscDraftProgressivePromosStore } from '~/stores/miscDraftProgressivePromos'

export default defineNuxtComponent({
  mixins: [intercomMixin, snackbarCartMixin],
  components: {
    CampaignFooter,
    DraftInfluencers,
    NavBarrenPrevious,
  },
  setup() {
    definePageMeta({
      middleware: ['is-logged-in', 'is-band', 'draft-id'],
    })
  },
  async mounted() {
    // progressive promos check - use this instead of middleware to not block the page
    await useMiscDraftProgressivePromosStore().FETCH()
    await useDraftPromoStore().CHECK_FOR_PROGRESSIVE_PROMO()
  },
  computed: {
    ...mapState(useCartStore, ['INFLUENCERS']),
    ...mapState(useInfluencersStore, { INFLUENCERS_LIST: 'list' }),
    ...mapState(useDraftStore, {
      DRAFT_ID: 'id',
      DRAFT_INFLUENCERS: 'influencers',
      DRAFT_INFLUENCERS_COUNT: 'influencers_count',
    }),
    draftId(): number | undefined {
      const { draftId } = this.$route.query
      return draftId ? Number(draftId) : undefined
    },
  },
  watch: {
    DRAFT_INFLUENCERS(newVal) {
      if (newVal.length === this.DRAFT_INFLUENCERS_COUNT)
        this.fetchInfluencers()
    },
    cartLength(newCartLength): void {
      if (newCartLength <= 0) this.$router.go(-1)
    },
  },
  methods: {
    ...mapActions(useInfluencersStore, ['FETCH_SET']),
    fetchInfluencers(): void {
      this.FETCH_SET(
        this.DRAFT_INFLUENCERS.filter(
          (id: number) => !this.INFLUENCERS_LIST.find((inf) => inf.id === id),
        ),
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.content {
  margin-top: 80px;
  margin-bottom: 164px;
}

@media screen and (min-width: 480px) {
  .content {
    margin-top: 110px;
  }
}

@media screen and (min-width: 1024px) {
  .navBarrenWrap {
    margin-top: 30px;
    margin-left: calc(var(--grid-2) + var(--grid-gap));
  }

  .content {
    margin-top: 48px;
    max-width: var(--grid-8);
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
