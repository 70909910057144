<template>
  <div
    id="ignoreFontUpscale"
    class="draftIdInfluencerWrapper ignoreFontUpscale"
  >
    <div class="tabWrapper">
      <ProgressivePromosInfoPanel
        v-if="
          IS_PROGRESSIVE_PROMOS_ENABLED &&
          !$route.path.includes('loading') &&
          !hidePromoBanner
        "
        class="tw-left-0 tw-top-0 tw-z-50 tw-overflow-hidden lg:tw-sticky"
      />
      <div
        class="tw-sticky tw-left-0 tw-top-0 tw-z-50"
        :class="{
          'lg:tw-top-[120px]':
            IS_PROGRESSIVE_PROMOS_ENABLED && !hidePromoBanner,
        }"
      >
        <NavBarren class="block" @nav="goPrevious">
          <div key="step2">
            {{
              $t('hub.stepper.mobileStep', {
                step: 2,
                stepsCount: 4,
                description: $t('draft.progressBar.step2'),
              })
            }}
          </div>
        </NavBarren>
        <DraftInfluencersFiltersRow
          @open-filters="handleOutlineBtnClick"
          @go-previous="goPrevious"
        />
      </div>
      <DraftGrooverListsRow
        v-if="hasGrooverMadeLists && SCREEN_WIDTH >= breakpoints.lg"
        :buckets="grooverMadeLists"
      />
      <InfluencerHub
        :has-nav-barren="true"
        :hide-filter-open-button="true"
        :ignore-filter-row-screen-size-rules="false"
      />
    </div>
    <CampaignFooter
      v-if="DRAFT_ID !== 0"
      page="sendtrack"
      :draft-id="DRAFT_ID"
    />
  </div>
</template>

<script lang="ts">
import isEqual from 'lodash.isequal'
import { getActivePinia, mapActions, mapState } from 'pinia'

import DraftGrooverListsRow from '~/components/draft/DraftGrooverListsRow.vue'
import DraftInfluencersFiltersRow from '~/components/draft/DraftInfluencersFilters/DraftInfluencersFiltersRow.vue'
import InfluencerHub from '~/components/draft/influencerHub.vue'
import ProgressivePromosInfoPanel from '~/components/draft/progressivePromos/infoPanel.vue'
import NavBarren from '~/components/nav/barren.vue'
import CampaignFooter from '~/components/ui/CampaignFooter/index.vue'

import CatalogFilterCuratorTypesMixin from '~/mixins/draft/CatalogFilterCuratorTypes'
import DraftInfluencersPageMixin from '~/mixins/draft/DraftInfluencersPage'

import { useCartSnackbars } from '~/composables/useCartSnackbars'

import { useDraftStore } from '~/stores/draft'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useInfluencersDuplicatesStore } from '~/stores/influencersDuplicates'
import { useMiscBandSignupStore } from '~/stores/miscBandSignup'
import { useMiscDraftProgressivePromosStore } from '~/stores/miscDraftProgressivePromos'
import { useMiscSendtrackSearchStore } from '~/stores/miscSendtrackSearch'
import { useUserBandStore } from '~/stores/userBand'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import { isFeatureOnForGroup } from '~/helpers/GrowthBookHelpers'

import { Breakpoints } from '~/enums/breakpoints'

import type Bucket from '~/types/bucket'

export default defineNuxtComponent({
  mixins: [CatalogFilterCuratorTypesMixin, DraftInfluencersPageMixin],
  components: {
    CampaignFooter,
    DraftGrooverListsRow,
    DraftInfluencersFiltersRow,
    InfluencerHub,
    NavBarren,
    ProgressivePromosInfoPanel,
  },
  setup() {
    definePageMeta({
      middleware: [
        'is-logged-in',
        'is-band',
        function (to) {
          const { $localePath } = useNuxtApp()
          const draftStore = useDraftStore(getActivePinia())

          if (to.hash.includes('#loaded') && process.client) return

          const draftId = draftStore.id || to.params.id

          return navigateTo(
            $localePath(`/draft/${draftId}/influencers/loading/`),
          )
        },
        function () {
          const miscBandSignupStore = useMiscBandSignupStore(getActivePinia())
          miscBandSignupStore.SYNC_WIDGET_CONTEXT()
        },
        'draft-id', // make sure we get draft influencers
      ],
    })

    const { FETCH_DUPLICATE_SUBMISSIONS } = useInfluencersDuplicatesStore()

    useCartSnackbars()
    // this call provides data for mismatches
    useLazyAsyncData(FETCH_DUPLICATE_SUBMISSIONS)
  },
  data() {
    return {
      draftId: undefined as number | undefined,
      trackId: 0,
      breakpoints: Breakpoints,
    }
  },
  computed: {
    ...mapState(useUserBandStore, { BAND_ID: 'id' }),
    ...mapState(useDraftTrackStore, { TRACK_ID: 'id' }),
    ...mapState(useUserFavoritesStore, { BUCKETS: 'buckets' }),
    ...mapState(useMiscDraftProgressivePromosStore, {
      IS_PROGRESSIVE_PROMOS_ENABLED: 'IS_ENABLED',
    }),
    grooverMadeLists(): Bucket[] {
      return this.BUCKETS.filter(
        (b: Bucket) => b.is_groover_made && b.band === this.BAND_ID,
      )
    },
    hasGrooverMadeLists(): boolean {
      return !!this.grooverMadeLists.length
    },
    hasPrevious(): boolean {
      return false
    },
    // Start AB Test 'hide-discount-banner'
    hidePromoBanner(): boolean {
      return isFeatureOnForGroup(
        this.$growthBook,
        'hide-discount-banner',
        'banner-hidden',
      )
    },
    // End AB Test 'hide-discount-banner
  },
  async mounted() {
    // save local copies of draftId & trackId since the store clears them out when going to certain routes
    this.draftId = Number(this.$route.params.id)
    this.trackId = this.TRACK_ID

    // save filters when page is closed/refreshed/blurred since these actions don't trigger the `beforeUnmount` hook
    window.addEventListener('beforeunload', this.updateDraft)
    window.addEventListener('blur', this.saveDraftTags)

    this.SET_SELECTED_GOALS([...this.DRAFT.goals])
    await this.syncOldGoalsWithTypeTags()

    // used to detect if the user opens a new tab
    localStorage.setItem('windowId', 'catalog-window')
  },
  beforeUnmount() {
    this.updateDraft()
    this.RESET_SEARCH({ shouldExecuteFilters: false })
    window.removeEventListener('beforeunload', this.updateDraft)
    window.removeEventListener('blur', this.saveDraftTags)
    localStorage.removeItem('windowId')
  },
  methods: {
    ...mapActions(useDraftStore, {
      UPDATE_DRAFT_ON_SERVER: 'UPDATE_SERVER',
    }),
    ...mapActions(useMiscSendtrackSearchStore, { RESET_SEARCH: 'RESET' }),
    /**
     * Called while the component is being destroyed or after the window object is being unloaded.
     * It saves the tags/goals to the draft when necessary.
     */
    async updateDraft(): Promise<void> {
      await this.saveDraftTags()

      const shouldResetFilters =
        this.$route &&
        !this.$route.path.includes('/influencer/profile/') &&
        !this.$route.path.includes('/draft/influencers/') &&
        !this.$route.path.includes('/influencers/lists/')

      if (shouldResetFilters) this.EMPTY_FILTERS()
    },
    async saveDraftTags() {
      if (
        isEqual(this.SELECTED_TAG_IDS, this.DRAFT.tags) &&
        isEqual(this.SELECTED_GOALS, this.DRAFT.goals)
      )
        return

      await this.UPDATE_DRAFT_ON_SERVER({
        patch: {
          goals: this.SELECTED_GOALS,
          tags: this.SELECTED_TAG_IDS,
        },
        draftId: this.draftId,
        track: { id: this.trackId },
        withoutSetDraft: this.draftId !== this.DRAFT.id,
      })
    },
  },
})
</script>

<style scoped lang="scss">
div {
  .draftIdInfluencerWrapper {
    @apply tw-mt-0;
  }
}

.tabWrapper.IS_PROGRESSIVE_PROMOS_ENABLED :deep(.headOuterWrapper) {
  @screen lg {
    top: 120px;
  }
}

.previous {
  @apply tw-absolute tw-mx-0 tw-my-auto tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-px-5 tw-py-4 tw-text-gray-500;

  right: calc(100% + 16px);
}
</style>
