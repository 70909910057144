<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{ $t(`influencer.signup.bubble.picture`) }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-max-w-3xl lg:tw-ml-48">
      <VCard :padding="`${SCREEN_WIDTH < 768 ? 6 : 10}`">
        <div class="tw-grid tw-gap-4">
          <div
            class="tw-relative tw-mx-auto tw-h-[120px] tw-w-[120px] tw-overflow-hidden tw-rounded-full sm:tw-h-[140px] sm:tw-w-[140px] md:tw-h-[160px] md:tw-w-[160px]"
            :class="{ 'tw-border-4 tw-border-error': displayErrorMessage }"
            @click="addPicture"
          >
            <div
              class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-transition-opacity tw-duration-150 tw-ease-in-out"
              :class="{ 'tw-opacity-0 hover:tw-opacity-100': picture !== null }"
            >
              <i
                v-if="!picture"
                class="fas fa-camera tw-text-lg tw-text-white"
              />
              <i v-else class="fas fa-pen tw-text-lg tw-text-white" />
            </div>
            <img
              :src="pictureSrc"
              :class="{ cancelRecenter: picture !== null }"
              class="recenterPicture tw-h-full tw-w-full tw-object-cover"
              alt=""
            />
            <div
              v-if="loading"
              class="tw-absolute tw-bottom-0 tw-top-0 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50"
            >
              <VText cfg="sans/16/medium" color="white">
                {{ uploadProgress }} %
              </VText>
            </div>
          </div>
          <VText cfg="sans/24/bold" class="tw-text-center">
            {{ INFLUENCER.entity }}
          </VText>
          <VText
            v-if="displayErrorMessage"
            cfg="sans/14/medium"
            color="error"
            class="tw-text-center"
          >
            {{ $t('influencer.signup.picture.error') }}
          </VText>
          <SignupUploadPictureButtons
            v-if="picture"
            class="tw-mt-4"
            @replace-picture="addPicture"
            @crop-picture="handleCropPictureClick"
          />
          <ImageCropper
            ref="cropper"
            :modal-title="$t('band.signup.uploadPicture.input.addPicture')"
            :pre-loaded-picture="preLoadedPicture"
            @zoom="handleZoomChange"
            @ready="handleCropperReadyEvent"
            @file-change="handleFileChange"
            @update-picture-upload-progress="updateUploadProgress"
          >
            <template #title>
              <div
                class="tw-absolute tw-top-8 tw-z-50 tw-flex tw-w-full tw-items-center tw-justify-center tw-transition-opacity tw-duration-150 tw-ease-in-out"
              >
                <div
                  class="tw-rounded-full tw-bg-black tw-bg-opacity-50 tw-px-4 tw-py-2 tw-text-white"
                >
                  <i class="fas fa-expand-arrows-alt" />
                  <VText cfg="sans/14/medium" color="white">
                    {{ $t('cropper.dragToMove') }}
                  </VText>
                </div>
              </div>
            </template>
            <template #actions>
              <div class="tw-p-6 tw-pb-[90px]">
                <InputRange
                  :model-value="zoomRatio"
                  :min="0"
                  :max="20"
                  :step="0.1"
                  class="tw-mb-6"
                  @update:model-value="setZoom($event)"
                />
                <ModalFooter
                  class="force_float tw-absolute tw-bottom-0 tw-left-0"
                  data-test-id="artistSignupUploadPictureFooter"
                  @next="confirmCrop"
                  @cancel="handleCropperCancel"
                />
              </div>
            </template>
          </ImageCropper>
        </div>
      </VCard>
    </div>
    <InfluencerOnboardingFlowNavigationFooter
      is-final-step
      @next="handleNext"
    />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState, mapStores } from 'pinia'

import SignupUploadPictureButtons from '~/components/band/signup/UploadPicture/SignupUploadPictureButtons.vue'
import ImageCropper from '~/components/images/cropper.vue'
import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import ModalFooter from '~/components/shared/modal/footer.vue'
import InputRange from '~/components/ui/Inputs/VRange.vue'
import VCard from '~/components/ui/VCard.vue'
import VText from '~/components/ui/VText.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

import pictureBuildHelper from '~/helpers/images/picture_build'

import { getPictureBinaryFromUrl } from '~/utils/getPictureBinaryFromUrl'

import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'

import { Breakpoints } from '~/enums/breakpoints'

import type { VueJsCropperEvent } from '~/components/images/cropper.vue'
import type { CropOriginEnum } from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryOnboarding'

export default defineNuxtComponent({
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    ImageCropper,
    InputRange,
    ModalFooter,
    SignupUploadPictureButtons,
    VCard,
    VText,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })

    const { getMediaUrl } = useGetMediaUrl()
    const localePath = useLocalePath()
    const runtimeConfig = useRuntimeConfig()
    const segmentTrack = useSegmentTrack()

    const cropper = ref<InstanceType<typeof ImageCropper> | null>(null)

    return {
      getMediaUrl,
      localePath,
      cropper,
      runtimeConfig,
      segmentTrack,
    }
  },
  data() {
    return {
      breakpoints: Breakpoints,
      preLoadedPicture: null as null | string,
      uncroppedFileSystemPicture: null as null | string,
      picture: null as null | string,
      loading: false,
      uploadProgress: 0,
      zoomRatio: 1,
      croppedFrom: null as null | CropOriginEnum,
      displayErrorMessage: false,
    }
  },
  computed: {
    ...mapState(useMiscResizeStore, { SCREEN_WIDTH: 'screenWidth' }),
    ...mapStores(useUserStore),
    ...mapStores(useUserInfluencerStore),
    USER() {
      return this.userStore.$state
    },
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
    pictureSrc(): string | undefined {
      return this.picture
        ? this.picture
        : this.getMediaUrl('/bandSignupPictureUpload.svg')
    },
    isValid(): boolean {
      return !!this.picture
    },
  },
  created() {
    if (this.INFLUENCER.has_profile_picture) {
      this.picture = pictureBuildHelper({
        kind: 'influencer',
        slug: this.INFLUENCER.slug,
        target: 'profile_picture',
        size: '400_400',
      })
    }
  },
  mounted() {
    this.preLoadedPicture = null
  },
  methods: {
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    completeFlow() {
      return navigateTo(this.localePath('/influencer/profile/'))
    },
    handleNext() {
      if (!this.isValid) {
        this.displayErrorMessage = true
        return
      }
      this.displayErrorMessage = false
      this.completeFlow()
    },
    addPicture(): void {
      this.croppedFrom = 'replace_flow'
      this.uncroppedFileSystemPicture = null
      const ref = this.cropper

      if (!ref) return

      ref.addPicture()
    },
    async handleCropPictureClick(): Promise<void> {
      this.croppedFrom = 'crop_flow'
      this.preLoadedPicture = null
      await this.$nextTick()

      if (!this.uncroppedFileSystemPicture && this.picture) {
        const dataURL = await getPictureBinaryFromUrl(this.picture)
        if (!dataURL) return

        this.preLoadedPicture = dataURL.toString()
        this.uncroppedFileSystemPicture = dataURL.toString()
        const ref = this.cropper

        if (!ref) return

        ref.open()
      } else {
        this.preLoadedPicture = this.uncroppedFileSystemPicture
        const ref = this.cropper

        if (!ref) return

        ref.open()
      }
    },
    handleZoomChange(value: VueJsCropperEvent): void {
      this.zoomRatio = value.detail.ratio
    },
    handleCropperReadyEvent(): void {
      this.setZoom(this.zoomRatio)
    },
    handleFileChange(imageSrc: string | undefined): void {
      if (imageSrc) this.uncroppedFileSystemPicture = imageSrc
    },
    updateUploadProgress(progress: number): void {
      this.uploadProgress = progress
    },
    setZoom(value: number): void {
      const ref = this.cropper

      if (!ref) return

      ref.zoomTo(value)
    },
    confirmCrop(): void {
      const ref = this.cropper

      if (!ref) return

      this.picture = ref.getCroppedCanvas() || null

      ref.close()
      this.$nextTick(() => {
        this.uploadPicture()
      })
    },
    handleCropperCancel(): void {
      const ref = this.cropper

      if (!ref) return

      ref.fullReset()
    },
    async uploadPicture(): Promise<void> {
      if (!this.picture) return

      this.loading = true
      this.uploadProgress = 0

      const ref = this.cropper

      if (!ref) return

      await ref.upload({
        id: this.USER.id,
        slug: this.INFLUENCER.slug,
        kind: 'influencer',
      })

      this.SET_INFLUENCER({ has_profile_picture: true })

      await this.refreshCache()

      this.loading = false

      if (this.croppedFrom) {
        this.segmentTrack('Onboarding - Crop Picture Finalized', {
          cropped_from: this.croppedFrom,
        })
      }

      this.croppedFrom = null

      this.displayErrorMessage = false
    },
    async refreshCache() {
      await provideCuratorRefreshCache($coreFetch)(this.INFLUENCER.id)
    },
  },
})
</script>

<style scoped lang="scss">
div.uploadPictureWrapper {
  @screen lg {
    margin-bottom: 100px;
  }
}

.recenterPicture:not(.cancelRecenter) {
  object-position: 3px 2px;
}
</style>
