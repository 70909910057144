<template>
  <div class="mainParentContainer ignoreFontUpscale tw-pt-6 md:tw-pt-12">
    <InfluencerOnboardingFlowThiagoBubble>
      {{
        $t(`influencer.signup.bubble.type`, {
          influencerName: INFLUENCER.entity,
        })
      }}
    </InfluencerOnboardingFlowThiagoBubble>
    <div class="tw-mb-32 tw-max-w-3xl lg:tw-mb-0 lg:tw-ml-48">
      <div class="tw-mb-8 tw-flex tw-flex-col tw-gap-2">
        <VLabel class="tw-mb-4" html-tag="label">
          <template #default>
            {{ $t('influencer.signup.type.label') }}
          </template>
        </VLabel>
        <div class="tw-mb-3 tw-flex tw-flex-wrap tw-gap-4">
          <VCard
            v-for="kind in AVAILABLE_KINDS"
            :key="kind.id"
            padding="4"
            class="tw-w-full tw-cursor-pointer tw-border tw-border-solid tw-transition-all tw-duration-150 tw-ease-in-out lg:tw-w-fit lg:hover:tw-shadow-hover"
            :class="{
              'tw-border tw-border-solid tw-border-orange-500 tw-bg-orange-100':
                selectedKinds.includes(kind.id) && !displayError,
              'tw-pointer-events-none tw-opacity-50':
                !selectedKinds.includes(kind.id) && isMaxItemsReached,
              'tw-border-red-500': displayError,
              'tw-border-transparent':
                !displayError && !selectedKinds.includes(kind.id),
            }"
            @click="handleSelectKind(kind.id)"
          >
            <div class="tw-flex tw-items-start tw-justify-between">
              <div class="tw-mr-2">
                <VText
                  cfg="sans/16/normal"
                  class="tw-mr-3 tw-flex tw-items-center"
                >
                  <span>{{ kind.i18n }}</span>
                  <span class="-tw-mt-1 tw-ml-2">
                    {{ $t(`influencer.signup.type.emoji.${kind.name}`) }}
                  </span>
                </VText>
              </div>
              <VCheckbox
                :model-value="selectedKinds.includes(kind.id)"
                :value-name="kind.id"
                @update:model-value="$emit('update:checkbox', $event)"
              />
            </div>
          </VCard>
        </div>
        <VText v-if="displayError" cfg="sans/14/medium" color="error">
          {{ $t('influencer.signup.type.error') }}
        </VText>
      </div>
    </div>
    <InfluencerOnboardingFlowNavigationFooter @next="handleNext" />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState, mapStores } from 'pinia'

import InfluencerOnboardingFlowNavigationFooter from '~/components/influencer/signup/InfluencerOnboardingFlowNavigationFooter.vue'
import InfluencerOnboardingFlowThiagoBubble from '~/components/influencer/signup/InfluencerOnboardingFlowThiagoBubble.vue'
import VCheckbox from '~/components/ui/Inputs/VCheckbox.vue'
import VCard from '~/components/ui/VCard.vue'
import VLabel from '~/components/ui/VLabel.vue'
import VText from '~/components/ui/VText.vue'

import { useInfluencerOnboardingFlowStore } from '~/stores/influencerOnboardingFlow'
import { useTagStore } from '~/stores/tag'
import { useUserInfluencerStore } from '~/stores/userInfluencer'
import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

import { provideCuratorRefreshCache } from '~/api-core/Curators/CuratorRefreshCache'

import type Tag from '~/entities/tag'
import type TagType from '~/entities/tagType'
import type { TranslateResult } from 'vue-i18n'

type TagWithI18N = Omit<Tag, 'toJSON'> & {
  i18n: TranslateResult
}

export default defineNuxtComponent({
  components: {
    InfluencerOnboardingFlowNavigationFooter,
    InfluencerOnboardingFlowThiagoBubble,
    VCard,
    VCheckbox,
    VLabel,
    VText,
  },
  setup() {
    definePageMeta({
      layout: 'onboarding-flow',
      middleware: ['onboarding-flow'],
    })
  },
  data() {
    return {
      selectedKinds: [] as number[],
      isMaxItemsReached: false,
      displayError: false,
      maxCount: 3,
    }
  },
  computed: {
    ...mapState(useTagStore, {
      GET_TAG_TYPE_FROM_NAME: 'GET_TYPE_FROM_NAME',
      GET_TAGS_FROM_IDS: 'GET_TAGS_FROM_IDS',
    }),
    ...mapStores(useUserInfluencerStore),
    ...mapStores(useUserInfluencerTagsStore),
    INFLUENCER() {
      return this.userInfluencerStore.$state
    },
    INFLUENCER_TAGS() {
      return this.userInfluencerTagsStore.$state
    },
    TAG_TYPE_INFLUENCER_KIND(): TagType | undefined {
      return this.GET_TAG_TYPE_FROM_NAME('influencer_kind')
    },
    AVAILABLE_KINDS(): TagWithI18N[] {
      const tags = this.GET_TAGS_FROM_IDS(
        this.TAG_TYPE_INFLUENCER_KIND?.tag_ids ?? [],
      ) as Tag[]

      return tags
        .filter((tag) => tag.name.toLowerCase() !== 'springboard')
        .sort((valA, valB) => valA.name.localeCompare(valB.name))
        .map((tag) => {
          return {
            ...tag,
            i18n: this.$t(`tags.influencer_kind.${tag.name}`),
          }
        })
    },
  },
  created() {
    if (this.INFLUENCER_TAGS.identity.influencer_kind.length > 0) {
      this.selectedKinds = [...this.INFLUENCER_TAGS.identity.influencer_kind]
      this.validate()
    }
  },
  methods: {
    ...mapActions(useInfluencerOnboardingFlowStore, {
      GO_TO_NEXT_STEP: 'GO_TO_NEXT_STEP',
    }),
    ...mapActions(useUserInfluencerStore, { SET_INFLUENCER: 'SET' }),
    ...mapActions(useUserInfluencerTagsStore, {
      UPDATE_INFLUENCER_TAGS_FROM_PATCH: 'UPDATE_FROM_PATCH',
    }),
    validate() {
      this.isMaxItemsReached = this.selectedKinds.length >= this.maxCount
    },
    handleSelectKind(kindId: number) {
      this.validate()

      const index = this.selectedKinds.indexOf(kindId)

      if (index !== -1) {
        this.selectedKinds.splice(index, 1)
      } else if (index === -1 && !this.isMaxItemsReached) {
        this.displayError = false
        this.selectedKinds.push(kindId)
      }

      this.validate()
    },
    async patchInfluencerKind() {
      return await this.UPDATE_INFLUENCER_TAGS_FROM_PATCH({
        patch: {
          identity: {
            influencer_kind: this.selectedKinds,
          },
        },
        influencer_id: this.INFLUENCER.id,
      })
    },
    async refreshCache() {
      await provideCuratorRefreshCache($coreFetch)(this.INFLUENCER.id)
    },
    async handleNext() {
      if (this.selectedKinds.length === 0) {
        this.displayError = true
        return
      }

      try {
        const influencer = await this.patchInfluencerKind()

        this.SET_INFLUENCER(influencer)

        await this.refreshCache()

        this.GO_TO_NEXT_STEP()
      } catch (error) {
        this.$sentry?.captureException(error)
      }
    },
  },
})
</script>
