import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_pIxqT1udyf from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iSwKVHXR68 from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xmjn21SsXL from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_h4zwG3hxhl from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@20.14.15_sass@1.7_xwynudlkh4qzdzxw6frfkdls5q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_a9GcwsZkBo from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_O6ajVblOhF from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_czxzw7dGcF from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GABEKUKE6K from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.5.4_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_W91pt1fYWA from "/root/front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@8.57.0_ioredis@_65m4gupjyunbg37asmquezz4l4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_shF6jEUGwr from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_x77zV66168 from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_KZrYadEYjC from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import fetch_14DdzPiXUB from "/root/front/plugins/fetch.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
export default [
  payloadToJson_f85A5nYSY0,
  revive_payload_client_pIxqT1udyf,
  unhead_iSwKVHXR68,
  router_xmjn21SsXL,
  _0_siteConfig_h4zwG3hxhl,
  payload_client_a9GcwsZkBo,
  navigation_repaint_client_O6ajVblOhF,
  chunk_reload_client_czxzw7dGcF,
  plugin_vue3_GABEKUKE6K,
  components_plugin_KR1HBZs4kY,
  prefetch_client_W91pt1fYWA,
  switch_locale_path_ssr_shF6jEUGwr,
  i18n_x77zV66168,
  plugin_KZrYadEYjC,
  polyfills_client_xo6BY28vLt,
  sentry_3AyO8nEfhE,
  fetch_14DdzPiXUB,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C
]